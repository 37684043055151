import ContainerMain from "pages/common/ContainerMain"

import image1 from "images/Product/Integrated/image-1.svg"
import "./Deliver.scss"
import { useFormatter } from "helpers/i18n"

export default function Deliver() {
  const { __ } = useFormatter()
  const contents1 = [
    {
      title: `${__({ defaultMessage: "Real-Time Order Tracking" })}`,

      description: `${__({
        defaultMessage:
          "View, track and process your orders and stay up-to-date at all times",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Bulk Order Printing" })}`,

      description: `${__({
        defaultMessage:
          "Automatically organizes orders by similarity and prioritizes orders with low SLAs",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Real-Time Synchronization" })}`,
      description: `${__({
        defaultMessage:
          "Stock is synchronized to all sales channels quickly and accurately",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Scan to Access" })}`,
      description: `${__({
        defaultMessage:
          "OctoPOS supports barcode scanning to quickly access product information",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Multi stockpoints with geo routing" })}`,
      description: `${__({
        defaultMessage:
          "Our algorithm will select the stockpoint that’s closest to shopper’s destination",
      })}`,
    },
  ]

  const contents2 = [
    {
      title: `${__({ defaultMessage: "SLA Auto-Prioritization" })}`,
      description: `${__({
        defaultMessage:
          "Auto-prioritizes processing orders with shorter SLAs to ensure “Ship-On-Time”",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Auto Manage Shipping Labels" })}`,
      description: `${__({
        defaultMessage:
          "Manage shipping labels in one place. No need to login to separate Seller Centers",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Low Stock Alert System" })}`,
      description: `${__({
        defaultMessage:
          "OctoPOS will predict if stock will run low based on sales history and alert you ahead of time",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Multi-sales Channels" })}`,
      description: `${__({
        defaultMessage:
          "Synchronize orders from different e-commerce platforms (Lazada, Shopee, Tiki, Sendo,…) into one application",
      })}`,
    },
    {
      title: `${__({ defaultMessage: "Real-Time Report & BI" })}`,
      description: `${__({
        defaultMessage:
          "Easy-to-understand and actionable snapshots of key performance metrics to help you focus on what matters to your operations and stock plans ",
      })}`,
    },
  ]

  return (
    <>
      <div className="hjjwtlvigw">
        <ContainerMain>
          <div className="txszrulamf">
            <div className="kuixqwbvwz">
              {__({ defaultMessage: " What We Deliver" })}
            </div>
            <div className="wtrorqvjqq">
              <div className="qcrqunblqy">
                {contents1.map((item, key) => {
                  return (
                    <div className="iyjsbvljrv" key={key}>
                      <div className="feonoypzfk">
                        <div className="nwwwyopygt">{item.title}</div>
                        <div className="kvrcetuodm">{item.description}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="bhfderjjqb">
                <img src={image1} alt="" className="pkisauhgre hover01" />
              </div>
              <div className="vxlnrhopnx">
                {contents2.map((item, key) => {
                  return (
                    <div className="iyjsbvljrv" key={key}>
                      <div className="feonoypzfk">
                        <div className="nwwwyopygt">{item.title}</div>
                        <div className="kvrcetuodm">{item.description}</div>
                      </div>
                    </div>
                  )
                })}
                <div className="bhfderjjqb">
                  <img src={image1} alt="" className="pkisauhgre-2 hover01" />
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
